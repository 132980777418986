import { Typography } from '@material-ui/core'
import React from 'react'

import Page from '@aletheia/common/components/Page'

const ErrorPage404: React.FC = () => {
  return (
    <Page title="Page Not Found">
      <Typography>Sorry, the page you requested could not be found.</Typography>
    </Page>
  )
}

export default ErrorPage404
